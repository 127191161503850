import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Il y a un an, nous étions 5.`}</p>
    <p>{`Maintenant, nous sommes 16 personnes chez La Consigne GreenGo. `}</p>
    <p>{`Répartis entre les différents pôles : produit, opérationnel, marketing, sales et tech, nous avons une mission commune : remettre la consigne au goût du jour et faire vivre une expérience simple aux utilisateurs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      